import Navbar from "react-bootstrap/Navbar";
import NavItem from "react-bootstrap/NavItem"; //Container
import Container from "react-bootstrap/Container";
import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { MdEmail } from "react-icons/md";
import Card from "react-bootstrap/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Icons
import {
	FaDesktop,
	FaDropbox,
	FaUsers,
	FaPhoneSquareAlt,
} from "react-icons/fa";

import "./Home.css";
//12,14
const logo = require("../images/back8.jpg");
const writeToUs = require("../images/back9.jpg");
const hcl_logo = require("../images/hcl_logo.png");
const vmware_logo = require("../images/vmware_logo.jpg");
const itcatalyst_logo = require("../images/itcatalyst_logo.png");
const kloudata_logo = require("../images/klouddata.png");
const queries_background = require("../images/query.png");

// Config Parameters
const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

var sectionStyle = {
	background: `url(${queries_background}`,
	overflow: "hidden",
	backgroundRepeat: "no",
};

function Home() {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [showWrite, setShowWrite] = useState(false);

	const handleCloseWrite = () => setShowWrite(false);
	const handleShowWrite = () => setShowWrite(true);

	const [to, setTo] = useState("");
	const [body, setBody] = useState("");

	const handleTo = (event) => {
		const value = event.target.value;
		setTo(value);
	};
	const handleBody = (event) => {
		const value = event.target.value;
		setBody(value);
	};

	return (
		<div>
			<Navbar
				variant="light"
				expand="lg"
				className="bg-body-tertiary justify-content-between outerNav"
			>
				<Container className="innerNav">
					<Navbar.Brand href="#home" className="brandNav">
						KLOUDPHOENIX
					</Navbar.Brand>
					<NavItem
						className="innerNav"
						onClick={() => window.location.replace("/#home")}
					>
						Home
					</NavItem>
					<NavItem
						className="innerNav"
						onClick={() => window.location.replace("/#aboutUs")}
					>
						About Us
					</NavItem>
					<NavItem
						className="innerNav"
						onClick={() => window.location.replace("/#services")}
					>
						Services
					</NavItem>
					<NavItem
						className="innerNav"
						onClick={() => window.location.replace("/#partners")}
					>
						Partners
					</NavItem>
					<NavItem>
						<Button
							className="successButtonNav"
							variant="success"
							onClick={handleShow}
						>
							Contact Us
						</Button>{" "}
						<Modal show={show} onHide={handleClose}>
							<Modal.Header closeButton>
								<Modal.Title>Contact Us</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Row>
									<Col md={1} className="iconPhone">
										<FaPhoneSquareAlt />
									</Col>
									<Col md={11}>+91-8867529996</Col>
								</Row>
								<Row>
									<Col md={1} className="iconEmail">
										<MdEmail />
									</Col>
									<Col md={11}>info@kloudphoenix.com</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="secondary"
									onClick={handleClose}
								>
									Close
								</Button>
							</Modal.Footer>
						</Modal>
						<Modal show={showWrite} onHide={handleCloseWrite}>
							<Modal.Title>
								<img
									className="background1Home"
									src={writeToUs}
									alt=""
								/>
								<Row>
									<Col md={12} className="writeUsDialog">
										Let's talk<br></br>
										<span className="writeUssub">
											Find how we can benefit you
										</span>
									</Col>
								</Row>
							</Modal.Title>
							<Modal.Body>
								<Form>
									<Form.Group
										className="mb-3"
										controlId="exampleForm.ControlInput1"
										onChange={handleTo}
									>
										<Form.Label>Your Email</Form.Label>

										<Form.Control
											type="email"
											placeholder="name@example.com"
										/>
									</Form.Group>
									<Form.Group
										className="mb-3"
										controlId="exampleForm.ControlTextarea1"
										onChange={handleBody}
									>
										<Form.Label>Your Message</Form.Label>
										<Form.Control as="textarea" rows={3} />
									</Form.Group>
								</Form>
							</Modal.Body>
							<Modal.Footer className="writeUsDialogButton">
								<Button
									variant="primary"
									onClick={(e) => {
										window.Email.send({
											Host: "smtp.elasticemail.com",
											Username: "info@kloudphoenix.com",
											Password:
												"96D1410625F35CF13852CF0720D7B6AD18A3",
											To: "info@kloudphoenix.com",
											From: "info@kloudphoenix.com",
											Subject: "Let/'s talk" + " " + to,
											Body: body + " " + to,
										}).then((message) => alert(message));
									}}
									className="writeUsDialogButtonContent"
								>
									Let's talk
								</Button>
							</Modal.Footer>
						</Modal>
					</NavItem>
				</Container>
			</Navbar>
			<div className="container-fluid" id="home">
				<img className="background1Home" src={logo} alt="" />
				<div className="bottom-left"></div>
				{/** 
				<div className="top-right">
					<Row className="textHomeRow">
						<Col className="textHome">Welcome To</Col>
					</Row>
					<Row>
						<Col className="textHome">KLOUDPHOENIX</Col>
					</Row>
					<Row>
						<Col className="textHomeSubHeading">
							Leaders in Solutions & IT Consulting
						</Col>
					</Row>
					<Row>
						<Col md={5}>
							<Button
								className="successButtonNav"
								variant="success"
								onClick={handleShow}
							>
								CONTACT US
							</Button>
						</Col>
					</Row>
				</div>
*/}
				<Row className="whyusHome" id="aboutUs">
					<Col className="d-flex justify-content-center" md={12}>
						<h1>About Us</h1>
					</Col>
				</Row>
				<Row className="whyusTextHome">
					<p className="whyusSubtextHome">
						Kloud Phoenix is a leading global Data Analytics
						Software services and solutions provider focused on
						delivering Predictive and Prescriptive Analytics (AI/ML)
						solutions,Oracle EBS/Cloud implementation solutions,
						Oracle BI/OAC implementation and support, Cloud Data
						Warehouse Implementation, Data Governance, Enterprise
						Service Bus, Microservices-based Integration Solutions,
						Big Data, Cloud DevOps, Salesforce CRM, and product
						engineering, etc. Kloud Phoenix has been an engineering
						and consulting services partner for Informatica,
						Matillion, Snowflake, MuleSoft, AWS, GCP, Azure,
						Tableau, Looker, Power BI, Adaptive Insights, Dell
						Boomi, and Talend.
					</p>
				</Row>

				<Row className="whyusHome" id="services">
					<Col className="d-flex justify-content-center" md={12}>
						<h1>Services</h1>
					</Col>
				</Row>
				<Row className="cardsHome">
					<Col>
						<Card className="text-center">
							<Card.Header className="iconsCardsHome">
								<FaDesktop />
							</Card.Header>
							<Card.Body>
								<Card.Title>IT Services & Solutions</Card.Title>
								<Card.Text>
									KLOUDPHOENIX understands the technology
									needs and provides appropriate Cost
									effective Solutions and Services
								</Card.Text>
								<Button
									className="successButtonNav"
									variant="success"
								>
									Know More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="text-center">
							<Card.Header className="iconsCardsHome">
								<FaDropbox />
							</Card.Header>
							<Card.Body>
								<Card.Title>Software Training</Card.Title>
								<Card.Text>
									KLOUDPHOENIX is a Unique Online, Onsite,
									On-Campus and Classroom Training & Academic
									Projects Assistance Platform.
								</Card.Text>
								<Button
									className="successButtonNav"
									variant="success"
								>
									Know More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col>
						<Card className="text-center">
							<Card.Header className="iconsCardsHome">
								<FaUsers />
							</Card.Header>
							<Card.Body>
								<Card.Title>Staffing & Recruitment</Card.Title>
								<Card.Text>
									KLOUDPHOENIX Staffing Services work with you
									to source the best talent for your
									organization
								</Card.Text>
								<Button
									className="successButtonNav"
									variant="success"
								>
									Know More
								</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row className="whyusHome" id="partners">
					<Col className="d-flex justify-content-center" md={12}>
						<h1>Partners</h1>
					</Col>
				</Row>
				<Row className="partnersCard">
					<Carousel
						swipeable={false}
						draggable={false}
						showDots={true}
						responsive={responsive}
						ssr={true} // means to render carousel on server-side.
						infinite={true}
						autoPlaySpeed={1000}
						keyBoardControl={true}
						customTransition="all .5"
						transitionDuration={500}
						containerClass="carousel-container"
						removeArrowOnDeviceType={["tablet", "mobile"]}
						dotListClass="custom-dot-list-style"
						itemClass="carousel-item-padding-40-px"
					>
						<div>
							<img
								className="partnerlogo"
								src={hcl_logo}
								alt="HCL"
							/>
						</div>
						<div>
							<img
								className="partnerlogo"
								src={vmware_logo}
								alt="vmware"
							/>
						</div>
						<div>
							<img
								className="partnerlogo"
								src={itcatalyst_logo}
								alt="IT Catalysts"
							/>
						</div>
						<div>
							<img
								className="partnerlogo"
								src={kloudata_logo}
								alt="Kloud Data"
							/>
						</div>
					</Carousel>
				</Row>
				<Row className="queries">
					<Col
						className="d-flex  queriesSub"
						md={12}
						style={sectionStyle}
					>
						<h1 className="textQueries">
							For Enquiries<br></br>
							<Button
								className="successButtonQueries"
								variant="success"
								onClick={handleShowWrite}
							>
								Write to Us
							</Button>{" "}
						</h1>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default Home;
