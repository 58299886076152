import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// TODO: Remove App
import App from "./App";
import Home from "./components/Home";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Initialise the route
// TODO :Seperate the router into another file
const router = createBrowserRouter([
	{
		path: "/",
		element: <Home></Home>,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router}>
			<App />
		</RouterProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
